import { Box, Divider, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { NavItem } from '../../types';

// A subcomponent of Layout.tsx, LayoutDrawer.tsx is the mobile layout for small screens.

type LayoutDrawerPropsType = {
  navItems: NavItem[];
  siteTitle: string;
  siteSubtitle: string;
  handleLayoutDrawerToggle: () => void;
};

const LayoutDrawer = (props: LayoutDrawerPropsType) => {
  const { navItems, siteTitle, siteSubtitle, handleLayoutDrawerToggle } = props;

  return (
    <Box onClick={handleLayoutDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ marginTop: 2, mx: 4, fontWeight: 'bold' }}>
        {siteTitle}
      </Typography>
      <Box sx={{ my: 1, mx: 5 }} />
      <Typography variant="subtitle2" sx={{ marginBottom: 2, mx: 4 }}>
        {siteSubtitle}
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton component={RouterLink} to={item.route} sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default LayoutDrawer;
