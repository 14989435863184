import { Box } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import ToDoItem from '../../types/ToDoItem';
import ToDoListItem from './ToDoListItem';

interface ToDoListProps {
  toDos: ToDoItem[];
  filter: string;
  toggleToDo: (id: string) => void;
  removeToDo: (id: string) => void;
}

const ToDoList = (props: ToDoListProps) => {
  const { toDos, filter, toggleToDo, removeToDo } = props;

  const [filteredToDos, setFilteredToDos] = useState(toDos);

  const filterTodos = useCallback(() => {
    switch (filter) {
      case 'active':
        setFilteredToDos(toDos.filter((toDo: ToDoItem) => toDo.completed === false));
        break;
      case 'completed':
        setFilteredToDos(toDos.filter((toDo: ToDoItem) => toDo.completed === true));
        break;
      case 'all':
        setFilteredToDos(toDos);
        break;
      default:
        setFilteredToDos(toDos);
        break;
    }
  }, [toDos, filter]);

  useEffect(() => {
    filterTodos();
  }, [toDos, filter, filterTodos]);

  return (
    <>
      {filteredToDos.length < 1 && toDos.length > 0 && <Box sx={{ p: 2 }}>{`No ${filter} ToDos`}</Box>}
      {filteredToDos.length > 0 && (
        <Box sx={{ p: 1 }}>
          {filteredToDos.map((item) => (
            <ToDoListItem toDo={item} toggleToDo={toggleToDo} removeToDo={removeToDo} key={item.id} />
          ))}
        </Box>
      )}
    </>
  );
};

export default ToDoList;
