import AppLayout from '../AppLayout/AppLayout';

/*
 *  Entry.tsx should contain no provider components, but is intended to include
 *  the very top level higher order components that your app may use, as well as the conditional logic
 *  required to determine which components to render.
 *  Examples include a login / logout component, layout component, branding component, etc.
 *
 */

const Entry = () => <AppLayout />;

export default Entry;
