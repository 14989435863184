import { NoteAdd } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { useToDoAdd } from '../../hooks';

interface AddTodoProps {
  setRefreshTodos: Dispatch<SetStateAction<boolean>>;
}

const AddTodo = (props: AddTodoProps) => {
  const { setRefreshTodos } = props;

  const [inputVal, setInputVal] = useState('');
  const { addToDo } = useToDoAdd(setRefreshTodos);

  const handleAdd = () => {
    addToDo(inputVal);
    setInputVal('');
  };

  return (
    <Box sx={{ p: 1 }} test-id="todo-addTodo">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleAdd();
        }}
      >
        <TextField
          id="add-toDo-input"
          fullWidth
          placeholder="enter new task..."
          value={inputVal}
          onChange={(e) => setInputVal(e.target.value)}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit" color="primary" disabled={!inputVal} edge="end">
                  <NoteAdd />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </form>
    </Box>
  );
};

export default AddTodo;
