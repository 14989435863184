import { Box, CircularProgress, Divider } from '@mui/material';
import { useState } from 'react';
import { AddToDo, ToDoFilter, ToDoList } from '../components';
import { useToDoDelete, useToDosGet, useToDoUpdate } from '../hooks';
import { ToDoItem } from '../types';
import './ToDoPage.css';

const title = 'My ToDos';

const ToDoPage = () => {
  const { toDos, toDosLoading, setRefreshToDos } = useToDosGet();
  const { deleteToDo } = useToDoDelete(setRefreshToDos);
  const { updateToDo } = useToDoUpdate(setRefreshToDos);

  const [filter, setFilter] = useState('all');

  const toggleToDo = (id: string) => {
    toDos.map((toDo: ToDoItem) => {
      const updatedTo = toDo;
      if (updatedTo.id === id) {
        updatedTo.completed = !updatedTo.completed;
        updateToDo(updatedTo);
      }
      return updatedTo;
    });
  };

  return (
    <div className="todo-app">
      <div className="todo-header">{title}</div>
      <AddToDo setRefreshTodos={setRefreshToDos} />
      <Divider />
      {toDosLoading ? (
        <Box sx={{ p: 2 }} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size="20px" aria-describedby="todo list" />
          <span>Loading ToDos...</span>
        </Box>
      ) : (
        !(toDos.length > 0) && <Box sx={{ p: 2 }}>No ToDos added yet</Box>
      )}
      {toDos.length > 0 && <ToDoList toDos={toDos} filter={filter} toggleToDo={toggleToDo} removeToDo={deleteToDo} />}
      <Divider />
      <ToDoFilter filter={filter} filterToDos={setFilter} />
    </div>
  );
};

export default ToDoPage;
