import { hookstate, useHookstate } from '@hookstate/core';
import initialAppSettingsState, { AppSettingsSchema } from './appSettings.state';

interface GlobalStateSchema {
  appSettings: AppSettingsSchema;
}

const initialState: GlobalStateSchema = JSON.parse(
  JSON.stringify({
    appSettings: initialAppSettingsState
  })
) as GlobalStateSchema;

const globalState = hookstate(initialState);

const useGlobalState = () => useHookstate(globalState);

export type { GlobalStateSchema };
export { initialState, globalState, useGlobalState };
