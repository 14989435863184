import axios from 'axios';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction } from 'react';
import AddToDoItem from '../types/AddToDoItem';
import { useGlobalState } from '../globalState/useGlobalState';

const useToDoAdd = (setRefreshToDos: Dispatch<SetStateAction<boolean>>) => {
  const { appSettings } = useGlobalState();
  const { enqueueSnackbar } = useSnackbar();

  const addToDo = (detail: string) => {
    const newToDo: AddToDoItem = {
      detail,
      completed: false
    };

    axios
      .post(`${appSettings.bffBaseUrl.get()}/api/todo`, newToDo)
      .then(() => {
        enqueueSnackbar('ToDo Task Added Successfully....', { variant: 'success' });
      })
      .catch((error: Error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setRefreshToDos(true);
      });
  };

  return {
    addToDo
  };
};

export default useToDoAdd;
