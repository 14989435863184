import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

interface ToDoFilterProps {
  filter: string;
  filterToDos: Dispatch<SetStateAction<string>>;
}

const ToDoFilter = (props: ToDoFilterProps) => {
  const { filter, filterToDos } = props;

  const handleFilter = (event: React.MouseEvent<HTMLElement>, newFilter: string) => {
    filterToDos(newFilter);
  };

  return (
    <Box sx={{ p: 1, display: 'flex', justifyContent: 'center' }}>
      <ToggleButtonGroup size="small" color="primary" value={filter} exclusive onChange={handleFilter}>
        <ToggleButton value="all">all</ToggleButton>
        <ToggleButton value="active">active</ToggleButton>
        <ToggleButton value="completed">completed</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default ToDoFilter;
