import Entry from '../Entry/Entry';

/*
 *   App.tsx serves to hold all non routing or authentication based provider components.
 *   Routing and authentication provider components should be wrapping this component in index.tsx
 *
 *   Examples to wrap the Entry component in this file include:
 *    - theme provider components
 *    - localization provider components,
 *    - logging & analytics provider components
 *    - etc.
 *
 */

const App = () => <Entry />;

export default App;
