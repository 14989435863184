import { Delete } from '@mui/icons-material';
import { Box, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import ToDoItem from '../../types/ToDoItem';

interface ToDoListItemProps {
  toDo: ToDoItem;
  toggleToDo: (id: string) => void;
  removeToDo: (id: string) => void;
}

const ToDoListItem = (props: ToDoListItemProps) => {
  const { toDo, toggleToDo, removeToDo } = props;

  const handleToggle = () => {
    toggleToDo(toDo.id);
  };

  const handleRemove = () => {
    removeToDo(toDo.id);
  };

  return (
    <Box display="flex" flexDirection="row">
      <Box flexGrow={1}>
        <FormControlLabel
          control={<Checkbox checked={toDo.completed} onChange={handleToggle} />}
          label={toDo.detail}
          labelPlacement="end"
        />
      </Box>
      <Box>
        <IconButton color="error" onClick={handleRemove}>
          <Delete />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ToDoListItem;
