import axios from 'axios';
import { useEffect } from 'react';
import { AppSettingsSchema } from '../globalState/appSettings.state';
import { useGlobalState } from '../globalState/useGlobalState';

const useAppSettings = () => {
  const { appSettings } = useGlobalState();

  useEffect(() => {
    if (!appSettings.loaded.get()) {
      axios
        .get<AppSettingsSchema>('/appsettings.json')
        .then((response) => {
          const { data } = response;
          appSettings.merge({
            loaded: true,
            bffBaseUrl: data.bffBaseUrl
          });
        })
        .catch((error: Error) => {
          // eslint-disable-next-line no-console
          console.error('Failed to retrieve App Settings!', error);
        });
    }
  }, [appSettings]);
};

export default useAppSettings;
