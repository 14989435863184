import { Menu } from '@mui/icons-material';
import { AppBar, Box, Button, CircularProgress, Drawer, IconButton, Toolbar, Typography } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink, Outlet } from 'react-router-dom';
import { useGlobalState } from '../../globalState/useGlobalState';
import { useAppSettings } from '../../hooks';
import { NavItem } from '../../types';
import AppLayoutDrawer from './AppLayoutDrawer';

/*
 *  Layout.tsx contains the static layout for the web page that may contain navigation, branding, site headers and etc.
 *  The layout does not change upon navigation from page to page within the application.
 *  Any page changes appear within the <Outlet /> component imported from react-router-dom
 *
 */

const drawerWidth = 240;
const siteTitle = 'Service Catalog Starter Kit';
const siteSubtitle = 'Cloud Native App';
const navItems: NavItem[] = [
  { name: 'Home', route: '/' },
  { name: 'About', route: 'about' }
];

const Layout = () => {
  useAppSettings();

  const { appSettings } = useGlobalState();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return appSettings.loaded.get() ? (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <Menu />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
            {siteTitle} - {siteSubtitle}
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button component={RouterLink} to={item.route} key={item.name} sx={{ color: '#fff' }}>
                {item.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth
            }
          }}
        >
          <AppLayoutDrawer
            navItems={navItems}
            handleLayoutDrawerToggle={handleDrawerToggle}
            siteTitle={siteTitle}
            siteSubtitle={siteSubtitle}
          />
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <SnackbarProvider maxSnack={3}>
          <Outlet />
        </SnackbarProvider>
      </Box>
    </Box>
  ) : (
    <CircularProgress aria-describedby="Sample App" />
  );
};

export default Layout;
