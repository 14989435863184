interface AppSettingsSchema {
  loaded: boolean;
  bffBaseUrl: string;
}

const initialAppSettingsState: AppSettingsSchema = JSON.parse(
  JSON.stringify({
    loaded: false,
    bffBaseUrl: ''
  })
) as AppSettingsSchema;

export type { AppSettingsSchema };
export default initialAppSettingsState;
