import axios from 'axios';
import { useSnackbar } from 'notistack';
import { Dispatch, SetStateAction } from 'react';
import { useGlobalState } from '../globalState/useGlobalState';
import ToDoItem from '../types/ToDoItem';

const useToDoUpdate = (setRefreshToDos: Dispatch<SetStateAction<boolean>>) => {
  const { appSettings } = useGlobalState();
  const { enqueueSnackbar } = useSnackbar();

  const updateToDo = (toDo: ToDoItem) => {
    axios
      .put(`${appSettings.bffBaseUrl.get()}/api/todo`, toDo)
      .then(() => {
        enqueueSnackbar('ToDo Task Updated Successfully....', { variant: 'success' });
      })
      .catch((error: Error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      })
      .finally(() => {
        setRefreshToDos(true);
      });
  };

  return {
    updateToDo
  };
};

export default useToDoUpdate;
