import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useGlobalState } from '../globalState/useGlobalState';
import { ToDoItem } from '../types';

const useToDosGet = () => {
  const { appSettings } = useGlobalState();
  const { enqueueSnackbar } = useSnackbar();

  const [toDos, setToDos] = useState<ToDoItem[]>([]);
  const [toDosLoading, setToDosLoading] = useState(true);
  const [refreshToDos, setRefreshToDos] = useState(true);

  useEffect(() => {
    if (refreshToDos) {
      axios
        .get<ToDoItem[]>(`${appSettings.bffBaseUrl.get()}/api/todo`)
        .then((response) => {
          // reverse order so lastest add on top of list
          const toDoData = response.data.reverse();
          setRefreshToDos(false);
          setToDos(toDoData);
          setToDosLoading(false);
        })
        .catch((error: Error) => {
          setToDosLoading(false);
          enqueueSnackbar(error.message, { variant: 'error' });
        });
    }
  }, [appSettings.bffBaseUrl, enqueueSnackbar, refreshToDos]);

  return {
    toDos,
    toDosLoading,
    setRefreshToDos
  };
};

export default useToDosGet;
