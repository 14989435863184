import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { App } from './components';
import './index.css';
import { AboutPage, ToDoPage } from './pages';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

/*
 *   Find the root DOM element and attach the React DOM root.
 *   Future provider components should be wrapped inside the <App /> component,
 *   while new BrowerserRouter routes are added here.
 *   Authentication providers may also wrap the application here.
 *
 *   Documentation for React Router can be found on https://reactrouter.com/docs/en/v6
 *
 */

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<ToDoPage />} />
          <Route path="about" element={<AboutPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
